import { sendEmailVerification, signInWithEmailAndPassword } from 'firebase/auth';
import { navigate } from 'gatsby';
import React from 'react'
import { auth } from '../../../firebase-config';
import CobraHeader from '../../client-ui/shared/CobraHeader';
import { Signin } from '../../locust/components/Signin';
import { LayoutFS } from '../../locust/composables/Layout';
import { getShoppingCart } from '../../service/shopping-cart';
import { Signup } from '../../locust/components/Signup';
import { ToastContainer, toast } from 'react-toastify';
import Register, { CheckYourEmail, DontSeeYourRegistrationEmail, Unregistered } from '../../locust/components/Register';
import { getCurrentUser } from '../../service/user-service';


enum PageState {
    SIGN_UP,
    CHECK_YOUR_EMAIL,
}

export default () => {

    const [pageState, setPageState] = React.useState(PageState.CHECK_YOUR_EMAIL)

    const [awaitingSignin, setAwaitingSignin] = React.useState(false)
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    const [passwordVerify, setPasswordVerify] = React.useState("")
    React.useEffect(()=>{
        setUsername(auth.currentUser?.email ? auth.currentUser.email : "")
        setPageState(PageState.SIGN_UP)
    },[])
    
    return (
        <>

            <LayoutFS min={350}>
                <CobraHeader type={'store'} shoppingCart={Object.keys(getShoppingCart()).length}/>
                
               
                    { PageState.SIGN_UP === pageState && 
                        <Unregistered username={username} setUsername={setUsername} onClick={async ()=>{
                            const user  = getCurrentUser()
                            if(user) sendEmailVerification( user ).then(()=>{
                                setPageState(PageState.CHECK_YOUR_EMAIL)
                                
                            }).catch(( error )=>{
                                
                                if(error.code === "auth/too-many-requests") navigate("/auth/attempts")
                            })

                            //
                            //signOut(auth)
                        }}/>
                    }

                    {PageState.CHECK_YOUR_EMAIL === pageState && 
                        <CheckYourEmail/>
                    }
                

           
            </LayoutFS>
            <ToastContainer hideProgressBar autoClose={2000} position="bottom-right"/>
        
        </>
    )
}
