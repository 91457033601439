import { Button, TextField } from "@mui/material"
import { css } from "glamor"
import React, { ReactNode, useState } from "react"
import { FACEBOOK_BG, FACEBOOK_BLUE, FACEBOOK_BLACK } from "../../assets/colors/Colors"
import { GlobalConfig, validateConfig } from "../composables/Layout"
import X from "../composables/X"
import SpacerV from "../sections/SpacerV"
import CircularProgress from '@mui/material/CircularProgress';
import { AuthLogo, AuthLogoLgCentered, FineprintRsp, SubtitleIIRsp, SubtitleRsp, TitleRspAlt } from "./Auth"
import { navigate } from "gatsby"

export enum AuthConstants {
    GUTTER = 40,
    FIELD_GUTTER = 16,
    FINEPRINT_GUTTER = 8
}

const Content = {
    signup : {
        title : `Check Your Email`,
        subtitle : 'Seriously. We love you.',
        fineprint  : 'By using this website, you agree to the Terms of Service and Privacy Policy, including Cookie Use.',
        signUpBtn : 'Register',
        hasAccount : `Want us to try and send it again?`,
    }
}



export default( props: { 
    children ?: any,
    username ?: string,
    setUsername ?: ( value : string ) => void 
    password ?: string,
    setPassword ?: ( value : string ) => void 
    awaitingSignin ?: boolean,
    onClick ?: () => void 

}) => {

   const config : GlobalConfig = validateConfig()
   //const signupBtnValue = useState<string|ReactNode>(Content.signup.signUpBtn)
   //const [signupBtnToggle, setSignupBtnToggle ] = useState(true)
   const [fineprintValue, setFineprintValue ] = useState(Content.signup.fineprint)
   const [fineprintColor, setFineprintColor ] = useState('silver')


   const signupBtnValue = props.awaitingSignin 
        ? <CircularProgress style={{ height : 20, width : 20, color : 'white'}}/>
        : Content.signup.signUpBtn


   return <div style={{ width : '100%', height : '100%', overflowX : 'scroll' }}>
     <SpacerV h={AuthConstants.GUTTER}/>
     <AuthLogoLgCentered/>
     <SpacerV h={AuthConstants.GUTTER}/>
     
     <TitleRspAlt c={FACEBOOK_BLACK}>{Content.signup.title}</TitleRspAlt>
     <SubtitleRsp c={FACEBOOK_BLACK}>{Content.signup.subtitle}</SubtitleRsp>
        
        <SpacerV h={AuthConstants.GUTTER}/>
        <X style={{ height : 55 }}>
            <TextField value={ props.username } 
                id="outlined-basic" 
                label="Activation Code" 
                variant="outlined" 
                style={{ width : 350, marginLeft : 10, marginRight : 10 }} 
                onChange={( event )=>{
                    if( props.setUsername )
                        props.setUsername(event.target.value)
                }}
            />
        </X>

        
        
        <SpacerV h={AuthConstants.FIELD_GUTTER}/>
        <X style={{ height : 55 }}>
            <Button
                disabled={ props.awaitingSignin }
                style={{ 
                    width : 350, 
                    marginLeft : 10, 
                    marginRight : 10,
                    height : 55, 
                    backgroundColor: FACEBOOK_BLACK, 
                    color: 'white',
                }}
                type="submit"
                
                variant="contained"
                onClick={()=>{
            
                    if( props.onClick )
                        props.onClick()
                }}
            >{signupBtnValue}</Button>
        </X>

        <SpacerV h={AuthConstants.FINEPRINT_GUTTER}/>
        <X style={{ height : 55}}>
            <FineprintRsp w={360} c={fineprintColor}>{fineprintValue}</FineprintRsp>    
        </X>
        
        <SpacerV h={AuthConstants.FIELD_GUTTER}/>
        <SubtitleIIRsp c={FACEBOOK_BLACK} >{Content.signup.hasAccount}</SubtitleIIRsp>



        <SpacerV h={AuthConstants.FINEPRINT_GUTTER}/>
        
        
        <X style={{ height : 55 }}>
            <Button
                style={{ 
                    width : 350, 
                    height : 55, 
                    marginLeft : 10,
                    marginRight : 10,
                    color: FACEBOOK_BLACK, 
                    backgroundColor: 'white',
                    border : `2px solid ${FACEBOOK_BLACK}`

                }}
                type="submit"
                
                variant="contained"
                onClick={()=>{
                    navigate("/auth/sign-in")
                }}
            >Resend</Button>
        </X>
        <SpacerV h={AuthConstants.FIELD_GUTTER*2}/>
        
                    
   </div>
}


export const DontSeeYourRegistrationEmail = ( props : {
    username ?: string, 
    setUsername ?: ( value : string ) => void 
    onClick ?: ( value ?: string ) => void
    subtitle ?: string 

}) => {
    
    const [fineprintColor, setFineprintColor ] = useState('silver')
    const [fineprintValue, setFineprintValue ] = useState(Content.signup.fineprint)
    return (
        <>

            <SpacerV h={AuthConstants.GUTTER}/>
            <AuthLogoLgCentered/>
            <SpacerV h={AuthConstants.GUTTER}/>
        
            <TitleRspAlt c={FACEBOOK_BLACK}>{Content.signup.title}</TitleRspAlt>
            <SubtitleRsp c={FACEBOOK_BLACK}>{props.subtitle !== undefined ? props.subtitle : 'Check your Email shortly'}</SubtitleRsp>
            
            <SpacerV h={AuthConstants.GUTTER}/>

            

            <SubtitleIIRsp c={FACEBOOK_BLACK} >{Content.signup.hasAccount}</SubtitleIIRsp>
            <SpacerV h={AuthConstants.GUTTER/4}/>
            <X style={{ height : 55}}>
                            <FineprintRsp w={360} c={fineprintColor}>{fineprintValue}</FineprintRsp>    
                        </X>
            <X style={{ height : 55 }}>
            <TextField value={ props.username } 
                id="outlined-basic" 
                label="Email" 
                variant="outlined" 
                style={{ width : 350, marginLeft : 10, marginRight : 10 }} 
                onChange={( event )=>{
                    if( props.setUsername )
                        props.setUsername(event.target.value)
                }}
            />
        </X>

        
        <SpacerV h={AuthConstants.FIELD_GUTTER}/>
        <X style={{ height : 55 }}>
            <Button
                style={{ 
                    width : 350, 
                    height : 55, 
                    marginLeft : 10,
                    marginRight : 10,
                    color: 'white',
                    backgroundColor: FACEBOOK_BLACK, 
                    border : `2px solid ${FACEBOOK_BLACK}`

                }}
                type="submit"
                
                variant="contained"
                onClick={()=>{ if(props.onClick)props.onClick( props.username ) }}
            >Resend</Button>
        </X>
        
        </>
    )
}


export const Unregistered = ( props : {
    username ?: string, 
    setUsername ?: ( value : string ) => void 
    onClick ?: () => void 

}) => {

    

    return (
        <>

            <SpacerV h={AuthConstants.GUTTER}/>
            <AuthLogoLgCentered/>
            <SpacerV h={AuthConstants.GUTTER}/>
        
            <TitleRspAlt c={FACEBOOK_BLACK}>{Content.signup.title}</TitleRspAlt>
            <SubtitleRsp c={FACEBOOK_BLACK}>Please verify it's really you.</SubtitleRsp>
            
            <SpacerV h={AuthConstants.GUTTER}/>

            
            <SubtitleIIRsp c={FACEBOOK_BLACK} >{Content.signup.hasAccount}</SubtitleIIRsp>
            <SpacerV h={AuthConstants.GUTTER/4}/>
            <X style={{ height : 55 }}>
            <TextField value={ props.username } 
                id="outlined-basic" 
                label="Email" 
                variant="outlined" 
                style={{ width : 350, marginLeft : 10, marginRight : 10 }} 
                onChange={( event )=>{
                    if( props.setUsername )
                        props.setUsername(event.target.value)
                }}
            />
        </X>

        
        <SpacerV h={AuthConstants.FIELD_GUTTER}/>
        <X style={{ height : 55 }}>
            <Button
                style={{ 
                    width : 350, 
                    height : 55, 
                    marginLeft : 10,
                    marginRight : 10,
                    color: FACEBOOK_BLACK, 
                    backgroundColor: 'white',
                    border : `2px solid ${FACEBOOK_BLACK}`

                }}
                type="submit"
                
                variant="contained"
                onClick={props.onClick}
            >Resend</Button>
        </X>
        
        </>
    )
}


export const CheckYourEmail = ( props : {
    

}) => {
    return (
        <>

            <SpacerV h={AuthConstants.GUTTER}/>
            <AuthLogoLgCentered/>
            <SpacerV h={AuthConstants.GUTTER}/>
        
            <TitleRspAlt c={FACEBOOK_BLACK}>{Content.signup.title}</TitleRspAlt>
            <SubtitleRsp c={FACEBOOK_BLACK}>Please check your email shortly</SubtitleRsp>
            
            <SpacerV h={AuthConstants.GUTTER}/>

            
            <SubtitleIIRsp c={FACEBOOK_BLACK}>We've been waiting for someone like you.</SubtitleIIRsp>
            <SpacerV h={AuthConstants.GUTTER/4}/>
            <X style={{ height : 55 }}>
           
        </X>

        
        
        
        </>
    )
}